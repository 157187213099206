// Package Imports
import React, { Fragment, lazy, Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import ReactGA from 'react-ga4'
import { ToastContainer } from "react-toastify";



const HomeRoute = lazy(() => import("./Routes/HomeRoute"));


 const TRACKING_ID="G-SH7H9W7N9Z";
 ReactGA.initialize(TRACKING_ID)

const App = () => {
  return (
    <Router>
      <Fragment>
        <Suspense fallback={<div>Loading...</div>}>
        <ToastContainer position="top-right" autoClose={5000} />
          <HomeRoute />
        </Suspense>
      </Fragment>
    </Router>
  );
};

export default App;
