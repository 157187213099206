// Types Imports
import { REGISTRATION_SUCCESS, REGISTRATION_FAIL } from '../Actions/Types';

// Defining Initial State
const initialState = {
    token: localStorage.getItem('token'),
    isAuthenticated: null,
    loading: true,
    isVerified: null,
    phone: null,
};

// Defining Reducer Function
export const authState = (state = initialState, action) => {
    // Destructuring Action
    const { type, payload } = action;

    switch (type) {
        // Successful Registration
        case REGISTRATION_SUCCESS: {
            // // Seting Token
            // localStorage.setItem('token', payload.token);

            // // Return State
            // return {
            //     ...state,
            //     ...payload,
            //     isAuthenticated: true,
            //     loading: false,
            // };

            // Return State
            return {
                ...state,
                ...payload,
                isAuthenticated: false,
                loading: false,
                isVerified: false,
                phone: payload.phone,
            };
        }

        // Failed Registration
        case REGISTRATION_FAIL: {
            // // Removing Token
            // localStorage.removeItem('token');

            // // Return State
            // return {
            //     ...state,
            //     token: null,
            //     isAuthenticated: false,
            //     loading: false,
            // };

            // Return State
            return {
                ...state,
                isAuthenticated: null,
                loading: false,
                isVerified: null,
                phone: null,
            };
        }

        // Default
        default: {
            // Return State
            return state;
        }
    }
};
