import { COOKIE_VALUE } from "../Actions/Types";

// Defining Initial State
const initialState = {
  cookieValue:""
};

// Defining Reducer Function
export const cookieState = (state = initialState, action) => {
// Destructuring Action
  const { type, payload } = action;

  switch (type) {
    // Add  Case
    case COOKIE_VALUE: {
      return {
        ...state,
        cookieValue: payload,
      };
    }
    // Default Case
    default: {
      return state;
    }
  }
};